import React, { useContext } from "react";
import { Link, graphql } from "gatsby";
// import Button from "../components/button";
import Layout from "../components/layout";
import SEO from "../components/seo";

import PropTypes from "prop-types";

import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";

import Button from "../components/button";
import { ThemeContext } from "../context/themeContext";

import styled from "styled-components";
const Content = styled.div`
     {
        font-size: 90%;
    }
    h2 {
        font-size: 1.25rem;
    }
    h3 {
        font-size: 1.125rem;
        margin-bottom: 0.75rem;
    }
    h4 {
        font-size: 1.125rem;
        margin-bottom: 1.5rem;
        color: #1155cc;
    }
    hr {
        margin: 1rem 0;
    }
`;

function Page({ data }) {
    let d = data.allContentfulMinisite.nodes[0];

    const { theme } = useContext(ThemeContext);
    let s = data.contentfulPageShipping;

    const options = {
        renderMark: {
            [MARKS.CODE]: (embedded) => (
                <div dangerouslySetInnerHTML={{ __html: embedded }} />
            ),
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
                return <img src={`https:${node.data.target.fixed.src}`} />;
            },
        },
    };

    return (
        <Layout>
            <SEO title={d.title} description={d.subtitle} />

            <section className="bg-white dark:bg-gray-700 py-6">
                <div className="w-full max-w-3xl mx-auto border border-gray-200 dark:border-transparent rounded-xl shadow-lg overflow-hidden bg-white dark:bg-gray-800">
                    <img src={d.featuredImage.file.url} alt="" />
                    <Content className="p-6">
                        {renderRichText(d.content, options)}
                    </Content>
                    <div className="w-full my-10 flex justify-center">
                        <Link
                            to={d.cta.url}
                            target="_blank"
                            className="px-8 py-6 rounded-full font-bold text-2xl tracking-wide"
                            style={{
                                color: `#5E4A00`,
                                background: `linear-gradient(180deg, #FFE100 0%, #FFC000 100%)`,
                            }}
                        >
                            {d.cta.text}
                        </Link>
                    </div>

                    <img src="../images/package_bg.png" alt="" />

                    <div
                        id="hero"
                        className={`bg-primary ${
                            theme === "dark" ? `bg-shipping-dark` : null
                        } flex-none md:flex w-full mx-auto -mt-10`}
                    >
                        <div className="mx-auto max-w-6xl px-8 py-8 md:pt-8 md:pb-24 text-center h-auto w-full">
                            <h2 className="text-xl md:text-3xl leading-tight mb-8 font-display breakline text-yellow-300 dark:text-gray-200">
                                {s.content.head.description}
                            </h2>

                            <p className="text-lg text-white dark:text-gray-200 sm:text-xl mt-10">
                                {s.content.body_1.description}
                            </p>
                        </div>
                    </div>

                    <div id="benefit" className="px-6 py-8">
                        <h4 className="text-2xl md:text-2xl leading-tight mb-4 font-display dark:text-gray-200">
                            {s.content.body_1.title}
                        </h4>

                        <div className="flex flex-wrap w-full">
                            {s.content.body_1.content.map((item, i) => (
                                <div
                                    className="flex w-full md:w-1/2 py-5 md:py-8 pr-4 md:pr-8 items-center"
                                    key={i}
                                >
                                    <div className="w-1/5 mb-2 mr-4">
                                        <img
                                            className="w-full h-auto"
                                            src={`../icons/shipping-benefit-${
                                                i + 1
                                            }.svg`}
                                            alt=""
                                        />
                                    </div>

                                    <div className="w-4/5">
                                        <h3 className="text-lg font-bold font-sans leading-none mb-4">
                                            {item.title}
                                        </h3>
                                        <p className="text-gray-500 dark:text-gray-400 leading-tight text-md">
                                            {item.desc}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div
                        id="couriers"
                        className="max-w-6xl mx-auto py-10 px-4 md:px-0"
                    >
                        <div className="p-6 dark:bg-gray-700">
                            <h4 className="text-xl mb-6 md:mb-10 text-center leading-tight font-display">
                                {s.content.head.expedition_title}
                            </h4>

                            <div className="flex flex-wrap justify-around items-center">
                                {s.content.head.expedition.map((item) => (
                                    <img
                                        key={item}
                                        src={item.url_logo}
                                        className="content-center flex mx-4 mb-6"
                                        style={{
                                            width: `auto`,
                                            height: `auto`,
                                            maxWidth: `120px`,
                                            maxHeight: `35px`,
                                        }}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>

                    <div
                        id="cta"
                        className="py-12 px-4 md:px-0 bg-secondary dark:bg-gray-800"
                    >
                        <div className="max-w-6xl mx-auto">
                            <div className="flex justify-center">
                                <Button
                                    key={s.content.cta.title}
                                    text={s.content.cta.title}
                                    size="xl"
                                    color="primary"
                                    url={s.content.cta.url}
                                    className="cta_bottom"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}

Page.propTypes = {
    data: PropTypes.object,
};

export const query = graphql`
    {
        allContentfulMinisite(
            filter: { title: { eq: "Free Ongkir Program" } }
        ) {
            nodes {
                title
                featuredImage {
                    file {
                        url
                    }
                }
                content {
                    raw
                    references {
                        ... on ContentfulAsset {
                            contentful_id
                            __typename
                            fixed(width: 800) {
                                width
                                height
                                src
                                srcSet
                            }
                        }
                    }
                }
                cta {
                    url
                    text
                }
            }
        }
        contentfulPageShipping {
            content {
                head {
                    description
                    expedition {
                        name
                        url_logo
                    }
                    expedition_title
                    title
                }
                body_1 {
                    content {
                        icon
                        title
                        desc
                    }
                    description
                    title
                }
                cta {
                    title
                    desc
                    url
                }
            }
        }
    }
`;

export default Page;
